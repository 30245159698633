<template>
  <div class="contact-body">
    <Header message="/contact"></Header>
    <div class="container">
        <div class="container-banner wow animate__animated animate__fadeInUp">
            <img class="banner-img" src="@/assets/image/falcon-tap-the1.png" alt="">
        </div>
        <div class="contactfrom">
            <div class="contactbox ">
                <div class="contactbox-left  wow animate__animated animate__zoomInDown">
                    <div class="leftbox">
                        <div class="boximg">
                            <div class="bgimg"></div>
                            <!-- <img class="bgimg" src="https://falcongames.com/wp-content/uploads/2022/06/Frame-5205.svg?lang=en" alt=""> -->
                        </div>
                        <div class="boxtext">
                            <h3>{{ studioName }}</h3>
                            <span>{{ studio1 }}</span>
                            <span>FLAT/RM D 8/F, SUN FAI COMMERCIAL CENTRE NO.576 RECLAMATION STREET, KL</span>
                            <img src="../../assets/image/icon_contact.png" alt="">
                            <!-- <ul>
                                <li><i class="iconfont icon-facebook"></i></li>
                                <li><i class="iconfont icon-guge_huaban-xin"></i></li>
                            </ul> -->
                        </div>
                        <div class="boxparent"></div>
                    </div>
                </div>
                <div class="contactbox-right  wow animate__animated animate__zoomInDown">
                    <div class="right-up">
                        <h6>Send us your message</h6>
                        <h4>For any kind of queries, please write to us below:</h4>
                    </div>
                    <div class="right-form">
                        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0.625rem" class="demo-ruleForm">
                            <el-form-item label="Name" prop="pass">
                                <el-input type="" v-model="ruleForm.pass" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="Email" prop="checkPass">
                                <el-input type="" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="Phone No" prop="checkPass">
                                <el-input type="" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="Contact" prop="age">
                                <el-input v-model.number="ruleForm.age" placeholder=""></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button style="background-color: #ED9A21; font-size: .0729rem; border: 0; width: .5208rem; height: .2604rem;"  @click="submitForm('ruleForm')">
                                    <a style="text-decoration: none; color: #fff;" href="mailto:support@Insomniacd.com?subject=【Guanwang】">send</a> <i class="el-icon-right"></i>
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:'Contact',
    components:{
        Header,
        Footer,
    },
    data(){
        var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('The field is required.'));
        }
      };
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('The field is required.'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('The field is required.'));
        } else {
          callback();
        }
      };
        return{
            studioName:' MAGIC MOBI LIMITED ',
            studio1:'While we don’t currently operate a dedicated helpline, we’re committed to addressing your queries promptly. Please fill in the details below to reach out to us. Our team will ensure your concerns are addressed effectively',
            ruleForm: {
                pass: '',
                checkPass: '',
                age: ''
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                age: [
                    { validator: checkAge, trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        toEmail() {
            console.log(123123132);
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
    height: 100%;
    background-color: #172532;
}
.container-banner{
    width: 100vw;
    height: 1.875rem;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    position: relative;
    top: 0;
}
.banner-img{
    width: 100vw;
    margin: 0 auto;
    position: absolute;
    bottom: -1.5625rem;
}
/* 背景虚化 */ 
.container-banner::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    top: 0;
    background: linear-gradient(0deg, rgba(23, 37, 50, 0.85), rgba(23, 37, 50, 0.85));
    z-index: 0;
}
.contactfrom{
    margin-top: -1.5625rem;
}
.contactbox{
    margin: .5599rem;
    padding: 0 .1667rem;
    display: flex;
}
.contactbox-left,.contactbox-right{
    width: 4.2604rem;
    height: 3.5496rem;
    padding: 0 .0781rem;
    z-index: 1;
}
.contactbox-right{
    padding: .1042rem 0 0 .2604rem;
}
.leftbox{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: .1563rem;
    justify-content: center;
    opacity: 1;
    transform: translateZ(0);
    height: 2.3767rem;
}
.boximg{
    position: absolute;
    top: 0;
    left: 0;
}
.boxtext{
    display: flex;
    flex-direction: column;
    z-index: 1;
    img{
        width: .4896rem;
        height: auto;
    }
    h3{
        font-size: .2344rem;
        color: #fff;
        margin: 0 0 .1042rem;
    }
    span{
        color: #fff;
        font-size: .0833rem;
        margin: 0 0 .1667rem;
        display: flex;
        align-items: center;
        transition: color .5s ease;
        cursor: pointer;
        i{
            color: #fff;
            font-size: .1563rem;
            display: block;
        }
    }
    span:hover{
        color: #ED9A21;
        i{
            color: #ED9A21;
        }
    }
    ul {
        display: flex;
        align-items: center;
        li {
            list-style: none;
            margin-right: .0521rem;
        }
    }
}
.icon-facebook{
    color: #ed9a21;
    font-size: .1823rem;
}
.icon-guge_huaban-xin{
    color: #ed9a21;
    font-size: .1563rem;
}

.right-up{
    
    h6{
        font-size: .0938rem;
        color: #ED9A21;
        border-bottom: .0052rem solid rgba(255, 255, 255, 0.3);
        padding-bottom: .1146rem;
        margin-bottom: .1146rem;
        
    }
    h4{
        margin-bottom: .2083rem;
        font-size: .1042rem;
        color: #fff;
    }
}
.el-button:focus, .el-button:hover{
    color: #fff;
    background-color: #172532;
    border: .0052rem solid #ED9A21;
}
.el-form-item__label{
    color: #fff !important;
}
.el-input__inner{
    background-color: #ffff0D !important;
}
.bgimg{
    width:4.1042rem;
    height:2.4392rem;
    border: .0365rem solid #ed9a21;
    border-radius: .0521rem;
}
.bgimg:before{
  border-top-right-radius: .0521rem;
  border-radius: .2604rem;
  background: #ED9A21;
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  display: block;
  width: .2604rem;
  height: .2604rem;
}
 
.bgimg:after{
  border-bottom-left-radius: .0521rem;
  border-radius: .2604rem;
  background: #ED9A21;
  position: absolute;
  right: 0;
  top: 0;
  content: '';
  display: block;
  width: .2604rem;
  height: .2604rem;
}
</style>