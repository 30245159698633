<template>
  <div class="box">
    <!-- <img class="box_logo" src="../../assets/image/top-logo.png" alt=""> -->
    <div class="container">
      <h1>Terms of use</h1>
      <!-- <p class="up_date" style="font-size: .0729rem;"><strong>DATED: 19 March 2024</strong></p> -->
      <ul class="ul_1">
        <p style="background-color: rgb(204 204 204);">ⓘ You agree to these terms when you use our games or any of our services.</p>
        <p>Welcome to  MAGIC MOBI LIMITED . This Agreement governs your access and use of products, content and services offered by  MAGIC MOBI LIMITED  (FLAT/RM D 8/F, SUN FAI COMMERCIAL CENTRE NO.576 RECLAMATION STREET, KL)  , such as game software and related updates, upgrades and features, and all online and mobile services, platforms, websites, and live events hosted by or associated with  MAGIC MOBI LIMITED  (collectively "MM Services").</p>
        <p style="background-color: rgb(204 204 204);">ⓘ If you don’t agree, please don’t install or use our games or services.</p>
        <p><strong>BY USING MM Services, YOU AGREE TO THESE TERMS. IF YOU DO NOT AGREE, DO NOT INSTALL OR USE THE MM Services.</strong></p>
        <li>
          <p><strong>Account</strong></p>
          <p style="background-color: rgb(204 204 204);">You need an Account to play stickman-Warriors Legends. To create one, you must be over a certain age and you may cancel your Account at any time.MM can suspend or terminate your account if you break this agreement.</p>
          <p>To create an Account, you must have a valid email address, and provide truthful and accurate information. You must be eligible to use the MM Service for which you are registering and must be a resident of a country where use of MM Services is permitted.</p>
        </li>
        <li>
          <p><strong>Availability of MM Services and Updates</strong></p>
          <p style="background-color: rgb(204 204 204);">ⓘ Our games and services may not always be available or operate on all devices. We also may make updates or changes to our games and services, which might impact your use or game progress.</p>
          <p>We do not guarantee that any MM Service, Content or Entitlement will be available at all times, in all locations, or at any given time or that we will continue to offer a particular MM Service, Content or Entitlements for any particular length of time. MM does not guarantee that MM Services can be accessed on all devices, by means of a specific Internet or connection provider, or in all geographic locations.</p>
          <p>From time to time, MM may update, change or modify an MM Service, Content or Entitlements, without notice to you. These updates and modifications may be required in order to continue to use MM Services.</p>
          <p>MM may need to update, or reset certain parameters to balance game play and usage of MM Services. These updates or "resets" may cause you setbacks within the relevant game world and may affect characters, games, groups or other Entitlements under your control.</p>
          <p>MM may also take actions on your Account and Entitlements without notice to you to protect you or MM, such as preventing unauthorized access, resetting Account passwords, suspending MM Account access, deleting data or removing Accounts from MM Services. Your availability to MM Services may also be affected in response to actual or suspected Rules of Conduct violations.</p>
        </li>
        <li>
          <p><strong>Rules of Conduct</strong></p>
          <p style="background-color: rgb(204 204 204);">ⓘ We want you to have a good time playing our games. So we expect you, like all players, to respect MM, our employees and representatives as well as your fellow players. This means, for example, obey the law, don’t cheat, don’t be offensive, don’t hack our software, don’t spam or bot, don’t lie to MM or our players. Those are the highlights. Read the full list of what not to do in the Rules of Conduct.</p>
          <p>When you access or use an MM Service, you agree that you will not:</p>
          <ul>
            <ul>
              <li>
                <p>Violate any law, rule or regulation.</p>
              </li>
              <li>
                <p>Interfere with or disrupt any MM Service or any server or network used to support or provide an MM Service, including any hacking or cracking into an MM Service.</p>
              </li>
              <li>
                <p>Use any software or program that damages, interferes with or disrupts an MM Service or another's computer or property, such as denial of service attacks, spamming, hacking, or uploading computer viruses, worms, Trojan horses, cancelbots, spyware, corrupted files and time bombs.</p>
              </li>
              <li>
                <p>Interfere with or disrupt another player's use of an MM Service. This includes disrupting the normal flow of game play, chat or dialogue within an MM Service by, for example, using vulgar or harassing language, being abusive, excessive shouting (all caps), spamming, flooding or hitting the return key repeatedly.</p>
              </li>
              <li>
                <p>Harass, threaten, bully, embarrass, spam or do anything else to another player or any MM employee or representative that is unwanted, such as repeatedly sending unwanted messages or making personal attacks or statements about race, sexual orientation, religion, heritage, etc. Hate speech is not tolerated. We won't tolerate any unwanted conduct against any MM employee and representative because of their affiliation in MM Services or anywhere else.</p>
              </li>
              <li>
                <p>Organize or participate in any activity, group or guild that is inappropriate, abusive, harassing, profane, threatening, hateful, offensive, vulgar, obscene, sexually explicit, defamatory, infringing, invades another's privacy, or is otherwise reasonably objectionable.</p>
              </li>
              <li>
                <p>Publish, post, upload or distribute MM or content that is illegal or that you don't have permission to freely distribute.</p>
              </li>
              <li>
                <p>Publish, post, upload or distribute any content, such as a topic, name, screen name, avatar, persona, or other material or information, that MM (acting reasonably and objectively) determines is inappropriate, abusive, hateful, harassing, profane, defamatory, threatening, obscene, sexually explicit, infringing, privacy-invasive, vulgar, offensive, indecent or unlawful.</p>
              </li>
              <li>
                <p>Post a message for any purpose other than personal communication. Prohibited messages include advertising, spam, chain letters, pyramid schemes and other types of solicitation or commercial activities.</p>
              </li>
              <li>
                <p>Impersonate another person or falsely imply that you are an MM employee or representative.</p>
              </li>
              <li>
                <p>Improperly use in-game support or complaint buttons or make false reports to MM staff.</p>
              </li>
              <li>
                <p>Attempt to obtain, or phish for, a password, account information, or other private information from anyone else on MM Services.</p>
              </li>
              <li>
                <p>Make use of any payment methods or refund systems to access, purchase or refund MM Services for fraudulent purposes, or without permission of the authorized owner, or otherwise concerning a criminal offence or other unlawful activity.</p>
              </li>
              <li>
                <p>Use any robot, spider or other automated device or process to access MM Services for any purpose such as scraping data, abuse MM Services, account creation, or copying material.</p>
              </li>
              <li>
                <p>Modify any file or any other part of the MM Service that MM does not specifically authorize you to modify.</p>
              </li>
              <li>
                <p>Use or distribute unauthorized software programs or tools (such as "auto", "macro", hack or cheat software), or use exploits, bugs or problems in an MM Service to gain unfair advantage.</p>
              </li>
              <li>
                <p>Engage or assist in cheating or other anticompetitive behavior (such as boosting, collusion, and match or matchmaking manipulation).</p>
              </li>
              <li>
                <p>Use or distribute counterfeit software or MM Content.</p>
              </li>
              <li>
                <p>Attempt to use an MM Service on or through any service that is not controlled or authorized by MM, or otherwise intentionally obfuscate your network connection or location or other metadata to gain access to an EA Service, make purchases, or otherwise access an MM Service.</p>
              </li>
              <li>
                <p>Sell, buy, share, trade or otherwise transfer or offer to transfer your MM Account, any personal access to MM Services, or any MM Content associated with your MM Account, including other Entitlements, either within an MM Service or on a third-party website, or in connection with any out-of-game transaction, unless expressly authorized by MM.</p>
              </li>
              <li>
                <p>Use an MM Service in a country in which MM is prohibited from offering such services under applicable export control laws.</p>
              </li>
              <li>
                <p>If an MM Service requires you to create a "username" or a "persona" to represent yourself in-game and online, you should not use your real name and may not use a username or persona that is used by someone else or that EA determines is vulgar or offensive or violates someone else's rights.</p>
              </li>
              <li>
                <p>Engage in any other activity that significantly disturbs the peaceful, fair and respectful gaming environment of a MM Service.</p>
              </li>
              <li>
                <p>Use information about users publicly available in any MM Service (e.g. on a leaderboard) for any purpose unrelated to the Service, including to attempt to identify such users in the real world.</p>
              </li>
              <li>
                <p>Promote, encourage or take part in any prohibited activity described above.</p>
              </li>
            </ul>
          </ul>
          <p style="background-color: rgb(204 204 204);">ⓘ To enforce these rules, we may monitor your activity and remove Account. If you don’t follow these rules, we may warn you, suspend you, ban you permanently or place other restrictions on your Account, games, or related services.</p>
          <p>If you or someone using your Account violates these rules and fails to remedy this violation after a warning,MM may take action against you, including revoking access to certain or all MM Services, Content or Entitlements, or terminating your Account . In case of severe violations,MM may take these actions without issuing a prior warning. Some examples of severe violations include, but are not limited to: promoting, encouraging or engaging in hacking, selling MM accounts or entitlements (including virtual currencies and items) without MM’s permission, extreme harassment, or threatening illegal activities. When practical, MM will notify you of the action it will take in response to violations of these rules or breach of this Agreement.</p>
          <p>Specific MM Services may post additional rules that apply to your conduct on those services.</p>
          <p>If you encounter another user who is violating any of these rules, please report this activity to MM using the "Help" or "Report Abuse" functions in the relevant MM Service, if available, or contact Customer Support at support@magicmobigame.com.</p>
          <p>MM may, in its discretion, monitor or record online activity or Content on MM Services and may remove any Content from any MM Service at its discretion. Remember that your communications in MM Service are public and will be seen by others.</p>
          <p>Your use of MM Services is subject to MM's Privacy and Cookie Policy , which is incorporated by reference into this Agreement.</p>
        </li>
        <li>
          <p><strong>Games</strong></p>
          <p>The Games may download and install updates, upgrades and additional features. You agree that MM has no obligation to support previous version(s) of the app upon the availability of an update, upgrade and/or implementation of additional features. MM may provide you with the option to download, install and use an alpha or beta version of the app under these same terms.</p>
        </li>
        <li>
          <p><strong>Termination and Other Sanctions</strong></p>
          <p style="background-color: rgb(204 204 204);">ⓘ If you break this agreement or the law,MM may suspend or terminate your use of our games and services, without refunds.If we decide to shut down a game or a service, we will tell you at least 30 days in advance.</p>
          <p>This Agreement is effective until terminated by you or MM. MM may terminate your access and use of any MM Services or your Account if MM determines that you have violated this Agreement or that there has been otherwise unlawful, improper or fraudulent use of MM Services on your Account. When practical, MM will notify you of the termination. You may lose your username and persona as a result of an Account termination. If you have more than one Account, depending on the type of violation or misuse, MM may terminate all of your Accounts and all related Entitlements. If your Account is terminated, you will not have access to your Account or Entitlements and may be barred from accessing or using any MM Service again. Upon termination, your license under this Agreement also shall terminate.</p>
          <p>Instead of termination and prior to any termination, MM may issue you a warning, suspend or alter your access to a particular MM Service or your Account, remove or revoke Entitlements at an Account or device level, remove or delete any Content which is in violation with this Agreement, or ban your device or machine from accessing specific MM Services. If MM takes any action described in this Section, you will not be entitled to a refund (subject to any statutory refund rights) and no Entitlements will be credited to you or converted to cash or other forms of reimbursement.</p>
          <p>MM may terminate any MM Service at any time by giving at least thirty days' notice either via email (if available), within the affected MM Service. After online service termination, no software updates will be applied to our games and we can't guarantee our games will continue to function on newer or updated operating systems or be available for download via application distribution services such as the iOS App Store and the Google Play Store. Any games available via such application distribution services after online service termination may be removed without further notice to you.</p>
        </li>
        <li>
          <p><strong>Use of Data</strong></p>
          <p style="background-color: rgb(204 204 204);">ⓘ MM collects various information when you play our games (even offline) to operate our business, improve our products and services, enforce our rules and communicate with you. We encourage you to read Cookie Policy .</p>
          <p>When you play the games, MM may collect and store data from your computer or device, including information about your computer or device, hardware, installed software, and operating system (such as IP Address and device ID), information about your MM Service usage, gameplay and usage statistics, system interactions and peripheral hardware. If you play an MM Service offline, this data will be stored on your device and transmitted to MM when your device connects to the Internet. MM uses this information to operate its business, improve its products and services, provide services to and communicate with you (including for marketing purposes), provide software updates, dynamically serve content and software support, enforce this Agreement, and trouble-shoot bugs or otherwise enhance your experience. If you participate in online services, MM also may collect, use, store, transmit and publicly display statistical data regarding game play (including scores, rankings and accomplishments), or identify content that is created and shared by you with other players.</p>
          <p>You can manage certain data collection preferences in the Settings tab of the game client.</p>
        </li>
        <li>
          <p><strong>Governing Law</strong></p>
          <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Nevada, United State.</p>
          <p><strong>Last Updated: March 7, 2024</strong></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions'
}
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  width: 100%;
  padding-bottom: .4167rem;
  h1 {
    text-align: center;
    margin: .4167rem;
    color: #2fccbf;
  }
  p{
    margin: 30px 0;
    line-height: 30px;
  }
}

.container {
  width: 6.7708rem;
  margin: 0 auto;
  .up_date{
    margin-bottom: .2604rem;
  }
  span{
    font-size: .0729rem;
    line-height: .1563rem;
  }
  .tit{
    display: block;
    margin: .1042rem 0;
  }
}

.ul_1 {
  margin-top: .2604rem;
  list-style-type: decimal ;
  margin-left: -1em;
  line-height: .1823rem;
  font-size: .1042rem;
  p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .ul_2 {
    font-size: .0729rem;
    list-style-type: upper-alpha;
    text-align: left;
    line-height: .1302rem;
    padding-left: .026rem;
    margin: .0521rem 0;
    .ul_3{
      font-size: .0729rem;
      list-style-type: disc;
      text-align: left;
      line-height: .1302rem;
      padding-left: .0521rem;
      margin: .0521rem 0;
    }
  }
}
.box_logo{
  width: 200px;
  height: auto;
  position: relative;
  left: 2.0313rem;
  top: .5729rem;
}
@media (max-width: 1000px) {
  .box_logo{
    width: 80px;
    height: auto;
    position: relative;
    left: .1042rem;
    top: 30px;
  }
  .container{
    h1{
      font-size: 24px;
    }
    .tit{
      font-size: 0.01em;
      line-height: 30px;
    }
}
  .ul_1 {
    font-size: .0521rem;
    strong{
      line-height:20px;
    }
    .ul_2{
      line-height: 30px;
      padding-left: .0521rem;
      .ul_3{
        line-height: 30px;
        padding-left: .0521rem;
      }
    }
  }
}

</style>